// src/pages/DetailsPage.js
import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Footer from "../Components/Footer"
import EnquiryModal from '../Components/EnquiryModal';
import { Helmet } from 'react-helmet';
import Owl from "../Components/Owl"
const DetailsPage = () => {
  const { id } = useParams(); // Get the ID from the URL
  const location = useLocation();
  const heading = location.state?.heading;
  const img = location.state?.img;
  const para = location.state?.para;
  const keyword_meta = location.state?.keyword_meta;
  const title = location.state?.title;
  const metadata = location.state?.metadata;
 
  // Example data fetching or use the ID to fetch specific data
  // For now, just display the ID
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={heading} />
        <meta name="keywords" content={keyword_meta} />
        {/* Add other meta tags here */}
      </Helmet>
      <div class="container-fluid py-5">
        <div class="container">

        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-4 my-3">
              <img src={img} class="border border-3 border-dark p-2 rounded shadow-lg" height={"350px"} style={{ objectFit: "cover" }} width={"100%"} alt="Rice" />
            </div>
            <div class="col-12 col-lg-8 my-3">


              <h4>{heading  + " " } </h4>
              <p>{para}</p>
 
                <a href="" className='btn btn-dark btn-sm' data-bs-toggle="modal" data-bs-target="#exampleModal">Enquiry Now</a>
             
            </div>
          </div>
        </div>
      </div>

      <EnquiryModal />

      <Owl />

      <Footer />


      {/* 
      <h1>Details Page</h1>
      <p>Details for card with ID: {id + heading + para}</p>
      <img src={img} height={"auto"} width={"200px"} /> */}
    </div>
  );
};

export default DetailsPage;
