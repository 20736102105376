import React from 'react'
import Footer from '../Components/Footer'
import { Helmet } from 'react-helmet';
import ContactComponebt from '../Components/ContactComponebt';
import Button from 'react-bootstrap/Button';



export default function Contact() {
   
    return (
        <>
            <Helmet>
                <title>Contact Us</title>
                <meta name="description" content="Contact" />
                <meta name="keywords" content="Contact" />
                {/* Add other meta tags here */}
            </Helmet>

            <ContactComponebt />
           

           
            <Footer />
        </>
    )
}
