import React, { useState } from 'react'

export default function ContactComponebt() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [message, setmessage] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior

        // Construct the WhatsApp URL
        const url = `https://wa.me/9742488718?text=Name:%20${encodeURIComponent(name)}%20Number:%20${encodeURIComponent(number)}%20Email:%20${encodeURIComponent(email)}%20Message:%20${encodeURIComponent(message)}`;

        // Open the URL in a new tab
        window.open(url, "_blank").focus();
    };
    return (
        <>
            <div className="jumbotron about_bg jumbotron-fluid " >
                <div className="container text-center py-5">
                    <h1 className="text-white display-4">Contact Us</h1>


                </div>
            </div>

            <section  >
                <div className="container text-center my-5">
                    <div className="section-title">
                        <h4 className="text-primary text-uppercase"  >Contact Us</h4>
                        <h1 className="display-6">Feel Free To Contact</h1>
                    </div>
                    <div className="row px-3 pb-2 my-5">
                        <div className="col-sm-4 text-center mb-3">
                            <i className="fa fa-2x fa-map-marker-alt mb-3 text-primary"></i>
                            <h4 className="font-weight-bold">Address</h4>
                            <p>No.02, Raika Plaza, Shankar Devara Mutt Lane, manivarthipet, Sulthanpet,Bengaluru,Karnataka,INDIA-560053</p>
                        </div>
                        <div className="col-sm-4 text-center mb-3">
                            <i className="fa fa-2x fa-phone-alt mb-3 text-primary"></i>
                            <h4 className="font-weight-bold">Phone</h4>
                            <p>+91 8073486436 || +91 9742488718</p>
                        </div>
                        <div className="col-sm-4 text-center mb-3">
                            <i className="far fa-2x fa-envelope mb-3 text-primary"></i>
                            <h4 className="font-weight-bold">Email</h4>
                            <p>suresh@raikaent.com</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='my-3'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <form action="" className="  ">
                                <div className="row">
                                    <div className="col-lg-6 my-2">
                                        <div className="form-floating mb-3">
                                            <input type="text" class="form-control name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter Your Name" />
                                            <label for=" ">Your Name</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 my-2">
                                        <div className=" form-floating mb-3">
                                            <input type="number" class="form-control number" value={number} onChange={(e) => setNumber(e.target.value)} placeholder="Enter Your Number" />
                                            <label for=" ">Your Phone</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 my-2">
                                        <div className="form-floating  mb-3">
                                            <input type="email" class="form-control email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Your Email" />
                                            <label for=" ">Your E-Mail</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 my-2">
                                        <div className="form-floating ">
                                            <textarea className="form-control message" value={message} onChange={(e) => setmessage(e.target.value)} placeholder="Leave a comment here"
                                                id=" " ></textarea>
                                            <label for=" ">Message</label>
                                        </div>
                                        <a href="" className="btn btn-success px-5 rounded-pill my-4" onClick={handleSubmit}  >Submit</a>
                                    </div>
                                </div>


                            </form>
                        </div>
                        <div className="col-lg-5">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945878.255864537!2d73.67953595288527!3d15.026156710607257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba35a4c37bf488f%3A0x41f1d28cd1757cd5!2sKarnataka!5e0!3m2!1sen!2sin!4v1725690811473!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
