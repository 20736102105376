import React, { useState } from 'react';
import pic from "../Image/banner/banner1.jpg"
import pic2 from "../Image/banner/banner2.jpg"
import demo from "../Image/demo.jpg"

import aboutus from "../Image/aboutus.jpg"

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import ProductData from '../ObjectsData/ProductData';
import ProductComponent from '../Components/ProductComponent';
import "slick-carousel/slick/slick-theme.css";

import Owl from "../Components/Owl"

import Number from '../Components/Number';
import Footer from "../Components/Footer"
import WhyChooseUs from '../Components/WhyChooseUs';


export default function Home() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior

        // Construct the WhatsApp URL
        const url = `https://wa.me/919742488718?text=Name:%20${encodeURIComponent(name)}%20Number:%20${encodeURIComponent(number)}%20Email:%20${encodeURIComponent(email)}`;

        // Open the URL in a new tab
        window.open(url, "_blank").focus();
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplat: true,
        slidesToShow: 4,
        slidesToScroll: 1,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <Helmet>
                <title> Raika Enterprises</title>
                <meta name="description" content="Raika Enterprises" />
                <meta name="keywords" content="Raika Enterprises" />
                {/* Add other meta tags here */}
            </Helmet>
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={pic} class="d-block w-100" alt="image" />
                    </div>
                    <div class="carousel-item">
                        <img src={pic2} class="d-block w-100" alt="image" />
                    </div>

                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
            {/* banner end here */}

            <div className="container margin">
                <div className="row">
                    <div className="col-lg-6 my-3">
                        <div className="content_heading">
                            <h3 className='fs-2  fw-bold p-0 m-0'>Since 2009 </h3>
                            <h4 className='display-6 text-primary fw-bold '>Raika Enterprises</h4>
                            <div className="bor my-3"></div>
                        </div>
                        <div className="contant_para">
                            <p>Welcome to <Link to="/About" className=' '>Raika Enterprises </Link> your trusted partner in procurement support. Our mission is to simplify the sourcing process and help industries secure the best deals on essential goods. With a focus on efficiency and reliability, we specialize in a diverse range of products:</p>
                            {/* <ul>
                                <li className='my-3'><b>Agricultural Goods : </b> From sugar and rice to various wheat grains, we ensure high-quality and competitive prices.</li>
                                <li className='my-3'><b>Metals : </b> We provide access to critical materials like copper cathode and mulberry, sourced to meet your specific needs.</li>
                                <li className='my-3'><b>Stationery Ready Goods : </b> Our selection includes a wide array of ready-to-use stationery items, ideal for any professional setting.</li>
                                <li className='my-3'><b>Corporate Gift Sets : </b>  Celebrate the New Year or other special occasions with our thoughtfully curated corporate gift sets.</li>
                            </ul> */}
                            <p>At Raika Enterprises, we are dedicated to finding the best sources for your procurement needs. Whether you're looking for specific products or seeking advice on sourcing strategies, we’re here to assist. Let us help you navigate the procurement landscape with ease and expertise.</p>
                            {/* <a href="" className='btn btn-outline-primary py-2 px-5 rounded-pill'>Send Enquiry</a> */}
                            <Link to="/About" className='btn btn-outline-primary py-2 px-5 rounded-pill'>About Us</Link>
                        </div>
                    </div>
                    <div className="col-lg-6 text-center my-3 ">
                        <img src={aboutus} height={"100%"} className='rounded border border-dark border-3' style={{ objectFit: "cover" }} width={"90%"} alt="no image" />
                    </div>
                </div>
            </div>
            {/* products */}
            {/* bannr start here */}

            <Owl />

            {/* 
            <div className='bg-dark py-5'>
                <div className="container">
                    <div className="content_heading text-center">
                        <h3 className='fs-2 text-white  fw-bold p-0 m-0'>Our Products</h3>
                        <div className="bor my-3 m-auto"></div>

                    </div>
                    <div className="row my-5">
                        <div className="col-12  slider-container" >
                            <Slider {...settings}>
                                {ProductData.map((e, index) => {
                                    return (
                                        <ProductComponent id={e.id} img={e.img} key={index} heading={e.heading} para={e.para} />

                                    );
                                })}

                            </Slider >
                        </div>
                    </div>
                </div>

            </div> */}

            {/* why choose us */}
            <div className="container margin">
                <div className="row justify-content-center">
                    <div className="col-7 ">
                        <div className="content_heading text-center">
                            <h3 className='fs-2  fw-bold p-0 m-0'>Why Choose Us? </h3>
                            <div className="bor my-3 m-auto"></div>

                         
                            <p className='my-2'>At Raika, we understand that efficient and cost-effective procurement is crucial for your business’s success. Here’s why you should partner with us:</p>
                        </div>
                    </div>
                </div>                
               
            </div>
            <WhyChooseUs/>
            
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Raika Enterprises
                            </h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12 col-md-6 my-2">
                                    <input type="text" class="form-control name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter Your Name" />
                                </div>
                                <div class="col-12 col-md-6 my-2">
                                    <input type="number" class="form-control number" value={number} onChange={(e) => setNumber(e.target.value)} placeholder="Enter Your Number" />
                                </div>
                                <div class="col-12  my-2">
                                    <input type="email" class="form-control email" value={email} onChange={(e) =>  setEmail(e.target.value)} placeholder="Enter Your Email" />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" onClick={handleSubmit} class="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* in number */}


            {/* <div className='my-5'>
                <div className='container text-center'>
                    <div className='row text-capitalize' >
                        <div className='col-12 '>
                            <h3 className='fs-2  fw-bold p-0 m-0 text- '>In Number</h3>
                        </div>
                        <div className='col-12'>
                            <div className='row justify-content-center'>
                                <Number name="15+ Years" massage="Trading and Procurement Service" />
                                <Number name="46MT" massage="ProductionAnnually" />
                                <Number name="19+" massage="   Containers Exported Annually" />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

 
            <Footer />
        </>
    )
}
