// src/pages/AboutUs.js
import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from "../Components/Footer"
import p1 from "../Image/mission.jpg"
import p2 from "../Image/vission.jpg"
import Owl from "../Components/Owl"
const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>Raika Enterprises</title>
        <meta name="description" content="Raika Enterprises" />
        <meta name="keywords" content="Raika Enterprises" />
        {/* Add other meta tags here */}
      </Helmet>





      <div className="about_bg ">
        <div className="container text-center   "  >
          <h1 className="text-white display-4">About Us</h1>


        </div>
      </div>
      <section>
        <div className="container my-5 ">
          <div className="section-title  ">
            <h1 className="display-4 text-primary fw-bold">Raika Enterprises</h1>
            <p className=" lh-lg">Welcome to Raika Enterprises, your premier partner in procurement support and sourcing solutions. At Raika Enterprises, we pride ourselves on delivering exceptional service with a commitment to 100% satisfaction, quick support, and quality service. Our goal is to bridge the gap between industries and their procurement needs, ensuring that every client receives the best products at the most favorable deals.</p>

            <p className=" lh-lg">Founded on the principles of integrity, efficiency, and customer-focused service, Raika Enterprises has grown to become a trusted name in the procurement industry. We understand that in today’s competitive market, finding reliable sources for essential goods can be challenging. That’s why we’ve dedicated ourselves to streamlining the procurement process and delivering value to businesses of all sizes.Our team of experienced professionals brings a wealth of knowledge and expertise to the table. We leverage our extensive network and industry insights to provide you with tailored solutions that meet your specific requirements. Whether you’re sourcing agricultural goods, metal products, stationery, corporate gifts, or need custom procurement solutions, we have the skills and resources to support your needs.</p>
            <p className=" lh-lg">Choosing Raika Enterprises means partnering with a company that values integrity, responsiveness, and excellence. We are dedicated to providing a seamless procurement experience that delivers real value to your business. Our team’s expertise, combined with our commitment to outstanding service, ensures that you can rely on us for all your procurement needs.</p>
            <p className=" lh-lg">Thank you for considering Raika Enterprises. We look forward to working with you and supporting your business’s success. For any inquiries or to learn more about our services, please contact us. We are here to help you achieve your procurement goals and enhance your operational efficiency.</p>

          </div>
        </div>
      </section>
       

      <section className="margin">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-6 my-2 mt-1">
              <div className="">
                <h4 className='text-primary'>Our Mission</h4>
                <p style={{textAlign : "justify"}}>"At Raika Enterprises, our mission is to empower businesses with exceptional procurement solutions that drive success and growth. We are dedicated to providing unparalleled support and sourcing excellence, ensuring that our clients receive the highest quality goods and services at the best possible deals. Our commitment to 100% satisfaction, quick support, and quality service guides everything we do. We strive to build lasting relationships with our clients by understanding their unique needs and delivering tailored solutions that exceed their expectations. Through our expertise and dedication, we aim to be the trusted partner that businesses turn to for all their procurement needs."</p>
              </div>
            </div>
            <div className="col-lg-4 mt-1 my-2 col-md-6">
              <img src={p1} height="auto" className='shadow-lg rounded border border-3 border-dark' width="100%" alt="mission" />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-4 mt-1 my-2 col-md-6">
              <img src={p2} className='shadow-lg rounded border border-3 border-dark' height="auto" width="100%" alt="vission" />
            </div>
            <div className="col-lg-8 col-md-6 my-2 mt-1">
              <div className="">
                <h4 className='text-primary'>Our Vision</h4>
                <p style={{textAlign : "justify"}}>"Our vision is to be the leading procurement support company recognized for transforming the procurement experience through innovation, reliability, and excellence. We aspire to set the standard in the industry by continuously enhancing our services and leveraging cutting-edge technology to meet the evolving needs of our clients. We envision a future where Raika Enterprises is synonymous with unmatched quality, responsiveness, and integrity in procurement. Our goal is to create lasting value for our clients and contribute to their long-term success by being the most trusted and forward-thinking procurement partner in the global market."</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AboutUs;


