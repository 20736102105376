import React from 'react'
import web2export from '../Image/web2export.jpg'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
export default function Footer() {
    return (
        <>

            <div className="container-fluid footer text-white   pt-5 px-0 bg-dark">
                <div className="row mx-0 pt-5 px-sm-3 px-lg-5 mt-4">
                    <div className="col-lg-3 col-md-6 mb-5">
                        <h4 className="text-white text-uppercase mb-4"  >Get In Touch</h4>
                        <p><i className="fa fa-map-marker-alt mr-2"></i> No.02, Raika Plaza, Shankar Devara Mutt Lane, manivarthipet, Sulthanpet,Bengaluru,Karnataka,INDIA-560053
                        </p>
                        <p><i className="fa fa-phone-alt mr-2"></i> <a href='tel:+8073486436' className='text-white' >+91 8073486436</a> / <a className='text-white' href="tel:+9742488718">+91-9742488718</a> </p>
                        <p className="m-0"><i className="fa fa-envelope mr-2"></i> <a className='text-white' href="mailto:suresh@raikaent.comsuresh@raikaent.com"> suresh@raikaent.com</a></p>
                    </div>
                    <div className="col-lg-2 col-md-6 mb-5">
                        <h4 className="text-white text-uppercase mb-4"  >Our Links</h4>

                        <ul className=" d-block">
                            <Link to="/" className='nav-link my-2 text-white'>Home</Link>
                            <Link to="/about" className='nav-link my-2 text-white'>About</Link>
                            <Link to="/services" className='nav-link  my-2 text-white'>Services</Link>
                            <Link to="/contact" className='nav-link  my-2text-white'>Contact</Link>
                        </ul>


                    </div>
                    <div className="col-lg-4 col-md-6 mb-5">
                        <h4 className="text-white text-uppercase mb-4" >About Us</h4>
                        <p className=""  >Welcome to <b>Raika Enterprises</b> your trusted partner in procurement support. Our mission is to simplify the sourcing process and help industries secure the best deals on essential goods. With a focus on efficiency and reliability, we specialize in a diverse range of products:</p>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-5">
                        <h4 className="text-white text-uppercase mb-4"  >Follow Us</h4>

                        <div className="d-flex justify-content-start">
                            
                            <a className="btn btn-lg btn-outline-light btn-lg-square mr-2" href="#"><FontAwesomeIcon icon={faFacebook} size=" " /></a>
               
                            <a className="btn btn-lg btn-outline-light btn-lg-square mr-2" href="#"><FontAwesomeIcon icon={faLinkedin} size=" " /></a>
                            <a className="btn btn-lg btn-outline-light btn-lg-square" href="#"> <FontAwesomeIcon icon={faInstagram} size=" " /></a>
                        </div>
                        <img src={web2export} className='rounded mt-3' height={"60px"} width={"200px"} alt={"no image"} />
                    </div>
                    <div className='col-12'>
                        <div className='border-top border-white my-2'></div>
                        <h6 className='py-3'>Copyright ©  <b className='text-info'>Raika Enterprises</b> All Rights Reserved. <span className='float-end'>    Designed by <a href="https://www.web2export.com" >Web2Export.com</a></span> </h6>
                    </div>
                </div>

            </div>
        </>


    )
}
