import React from 'react'
import ProductData from '../ObjectsData/ProductData';
import ProductComponent from '../Components/ProductComponent';
import Slider from "react-slick";
export default function Owl() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplat: true,
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
  return (
    <>
    
    <div className='bg-dark py-5 my-5'>
                <div className="container">
                    <div className="content_heading text-center">
                        <h3 className='fs-2 text-white  fw-bold p-0 m-0'>Our Products</h3>
                        <div className="bor my-3 m-auto"></div>

                    </div>
                    <div className="row my-5">
                        <div className="col-12  slider-container" >
                            <Slider {...settings}>
                                {ProductData.map((e, index) => {
                                    return (
                                        <ProductComponent id={e.id} img={e.img} key={index} heading={e.heading} para={e.para} metadata={e.metadata} keyword_meta={e.keyword_meta} title={e.title} />

                                    );
                                })}

                            </Slider >
                        </div>
                    </div>
                </div>

            </div>
      
    </>
  )
}
