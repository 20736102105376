import React from 'react'
import p1 from "../Image/icon/1.png"
import p2 from "../Image/icon/2.png"
import p3 from "../Image/icon/3.png"
import support from "../Image/Dedicated Support.jpg"
import experince from "../Image/Expertise and Experience.jpg"
 



export default function WhyChooseUs( ) {
    return (
        <>
            
            <section className="choose-us  my-5  ">
                <div className="container ">
                    <div className="row">
                        <div className="col-lg-4 my-3">
                            <div className="icon_box  p-3  h-100 bg-white text-center py-5 rounded shadow-lg">
                                <div className="box">
                                    <img src={p1} height="70px" width="70px" alt="Contact support | Hameediya Farms" />

                                </div>
                                <h5>Quick Support</h5>
                                <p>We recognize the importance of timely solutions in the fast-paced business world. Our team is dedicated to providing swift assistance and addressing your procurement needs promptly, ensuring minimal disruption to your operations.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 my-3">
                            <div className="icon_box p-3  h-100 bg-white text-center py-5 rounded shadow-lg">
                                <div className="box">
                                    <img src={p2} height="70px" width="70px" alt="1st Place" />

                                </div>
                                <h5>Quality Service</h5>
                                <p>Excellence in service is at the heart of what we do. From the initial inquiry to the final delivery, we maintain the highest standards of quality and professionalism, ensuring that you receive the best products and support.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 my-3">
                            <div className="icon_box  p-3 h-100 bg-white text-center py-5 rounded shadow-lg">
                                <div className="box">
                                    <img src={p3} height="70px" width="70px" alt="Review Hameediya Farms" />

                                </div>
                                <h5>100% Satisfaction</h5>
                                <p>Your satisfaction is our top priority. We are committed to understanding your unique needs and exceeding your expectations with every transaction. Our goal is to build lasting relationships through reliable and responsive service.</p>
                            </div>
                        </div>
                    </div>
                </div>


            </section>
            <div className=" fixed_bg ">
                <div className="container  py-5 ">
                    <div className="row g-0 bg-dark ">
                        <div className="col-lg-6 col-md-6">
                            <div className="img_hover rounded">
                                <img src={experince} height={"350px"} className='' width={"100%"} style={{ objectFit: "cover" }} alt="image" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 text-white align-self-center text-center">
                            <div className="text-center p-4">
                                <h4 className='display-6  fw-bold'>Expertise and Experience</h4>
                                <p> Our team has extensive experience in sourcing a wide range of products. We leverage our industry knowledge to secure the best deals and high-quality goods tailored to your needs.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row g-0 bg-dark ">

                        <div className="col-lg-6 col-md-6 text-white align-self-center text-center">
                            <div className="text-center p-4">
                                <h4 className='display-6 fw-bold'>Dedicated Support</h4>
                                <p>Our team is committed to providing exceptional customer support. Whether you have questions, need assistance, or require updates, we’re here to help every step of the way.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="img_hover rounded">
                                <img src={support} height={"350px"} className='' width={"100%"} style={{ objectFit: "cover" }} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
