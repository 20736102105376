
import './App.css';
import Home from './RouterPage/Home'
import About from './RouterPage/AboutUs'
import CardList from './cards/CardList';
import DetailsPage from './cards/DetailsPage';

import logo from "./Image/logo.png"
import NavLink from './Components/NavLink';
import ProductData from './ObjectsData/ProductData';
import Services from './RouterPage/Services';
import Contact from './RouterPage/Contact';
// import Delivering from './Components/Delivering'
// import WhyUs from './Components/WhyUs'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
function App() {
  return (
    <Router>
      <div className='bg-dark'>
        <div class="container  ">
          <div class="row py-2 px-2  ">
            <div class="col-lg-6 text-center text-lg-start mb-2 mb-lg-0">
              <div class="d-inline-flex align-items-center text-white">
                <small><i class="fa fa-phone-alt mr-2"></i> <a href='tel:+8073486436' className='text-white'>+91 8073486436</a></small>
                <small class="px-3">|</small>
                <small><i class="fa fa-envelope mr-2"></i> <a href="mailto:suresh@raikaent.com"  className='text-white'   > suresh@raikaent.com</a></small>
              </div>
            </div>
            <div class="col-lg-6 text-center text-lg-end">

              <div class="d-inline-flex align-items-center  ">
                <a class="text-white px-2" href="">
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a class="text-white px-2" href="">
                  <i class="fab fa-twitter"></i>
                </a>
                <a class="text-white px-2" href="">
                  <i class="fab fa-linkedin-in"></i>
                </a>
                <a class="text-white px-2" href="">
                  <i class="fab fa-instagram"></i>
                </a>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid p-0 shadow-sm  border-bottom border-light border-3 " >
        <nav class="navbar navbar-expand-lg  bg-white navbar-light py-3 py-lg-0 px-lg-5">
          <a href="index.php" class="navbar-brand ml-lg-3">
            <img src={logo} height="100px" width="100px" alt="Logo" />
          </a>
          <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
            <div class="navbar-nav ms-auto p-4">
              <Link className="nav-item nav-link fw-bold text-primary mx-2" to="/">Home</Link>
              <Link className="nav-item nav-link fw-bold text-primary mx-2" to="about">About</Link>


              <div class="nav-item dropdown  mx-2">
                <a href="#" class="nav-link dropdown-toggle text-primary fw-bold" data-bs-toggle="dropdown">Products</a>
                <div class="dropdown-menu text-capitalize">
                  {ProductData.map((e, index) => {
                    return (
                      <NavLink id={e.id} img={e.img} key={index} heading={e.heading} para={e.para} title={e.title} keyword_meta={e.keyword_meta}  metadata={e.metadata} />

                    );
                  })}
                </div>
              </div>
              <Link className="nav-item nav-link fw-bold text-primary mx-2" to="services">Our Services</Link>

              <Link className="nav-item nav-link fw-bold text-primary mx-2" to="contact">Contact Us</Link>
            </div>
          </div>
        </nav>
      </div>






















      {/* <nav className="navbar navbar-expand-lg navbar-dark py-3 bg-white ">
        <div className="container-fluid">
          <a className="navbar-brand mx-2" href="#">ritika</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link   text-white mx-2" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link   text-white mx-2" to="About">About Us</Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link   text-white mx-2" to="CardList">card</Link>
              </li>
              


            </ul>

          </div>
        </div>
      </nav> */}
      <div>

        <Routes>
          <Route exact path='/' element={<Home />}  ></Route>
          <Route path='about' element={<About />}  ></Route>
          <Route path='CardList' element={<CardList />}  ></Route>
          <Route path='/details/:id' element={<DetailsPage />}  ></Route>
          <Route path='services' element={<Services />}  ></Route>
          <Route path='contact' element={<Contact />}  ></Route>

        </Routes>
      </div>
      {/* */}


    </Router>


  );
}

export default App;


