const ProductData = [
    {
        key : 1,
        id:1,
        img: require("../Image/product/rice.jpg"),
        heading : "Rice",
        para: "Our Basmati rice is renowned for its aromatic fragrance and fluffy texture, making it the perfect choice for biryanis and pilafs. For a delicate, slightly sticky texture ideal for Thai dishes, our Jasmine rice is a top pick. We also provide versatile Long-Grain rice, which remains non-sticky and is perfect for a range of side dishes and stir-fries. For those seeking a healthier option, our Organic rice is cultivated without pesticides, ensuring a natural and wholesome taste. Each variety is processed with the utmost care to preserve its flavor and nutritional value, delivering quality you can trust",
        title:"Raika Enterprises",
        keyword_meta:" " ,
        metadata:" " ,
         
    },
    {
        key : 2,
        id:2,
        img: require("../Image/product/Sugar.jpg"),
        heading : "Sugar",
        para: "Sweetness is an essential part of life, and our sugar products are designed to meet all your needs with purity and consistency. Our Granulated Sugar is ideal for everyday use, whether you’re baking cookies or sweetening your coffee. If you’re looking for a richer flavor, our Brown Sugar adds a delightful molasses note to your recipes, perfect for cookies, sauces, and more. For smooth and fine texture in frostings and confections, our Powdered Sugar is a must-have. Additionally, our Raw Sugar provides a natural caramel flavor and rustic appeal, making it a great choice for adding a touch of charm to your dishes. Each product is processed to ensure it maintains the highest standards of taste and quality.",
        title:"Raika Enterprises",
        keyword_meta:"" ,
        metadata:"" ,
    } ,
     
    {
        key : 3,
        id:3,
        img: require("../Image/product/Wheat.jpg"),
        heading : "Wheat",
        para: "Our wheat products are crafted to support a wide range of baking and cooking needs. We offer Whole Wheat Flour, which is rich in nutrients and fiber, perfect for creating wholesome bread and baked goods. For a more versatile option, our All-Purpose Flour is finely milled and suitable for everything from pastries to sauces. Additionally, our Semolina is ideal for making pasta and traditional dishes, providing a unique texture and flavor. Each type of wheat is selected and processed to deliver exceptional quality and performance, ensuring your culinary creations are always top-notch.",
        title:"  Raika Enterprises",
        keyword_meta:"  " ,
        metadata:" " ,
    } ,
     
    {
        key : 4,
        id:4,
        img: require("../Image/product/Grains.jpg"),
        heading : "Grains ",
        para: "Embrace a healthy lifestyle with our diverse selection of grains. Our Quinoa is a complete protein, packed with essential nutrients, and is a great addition to salads and side dishes. Barley is another nutritious option, rich in vitamins and minerals, and works wonderfully in soups and stews. For a hearty breakfast, our Oats provide fiber and a range of health benefits. Millet, a versatile and easily digestible grain, can be used in various dishes to support a balanced diet. Each grain is chosen for its quality and nutritional value, helping you maintain a wholesome diet.",
        title:"Raika Enterprises",
        keyword_meta:" " ,
        metadata:" " ,
    } ,
    {
        key : 5,
        id:5,
        img: require("../Image/product/Copper cathode.jpg"),
        heading : "Copper cathode  ",
        para: "Our Copper Cathodes are designed to meet the highest industrial standards, providing exceptional conductivity and purity. Ideal for a range of manufacturing and industrial applications, our copper cathodes are crafted to ensure consistency and reliability. With a focus on quality, our cathodes are perfect for businesses that require top-grade materials for their production processes. Whether you’re involved in electronics, construction, or other industries, our copper cathodes offer the performance and dependability you need.",
        title:"Raika Enterprises",
        keyword_meta:" " ,
        metadata:" " ,
    } 
     ,
    {
        key : 6,
        id:5,
        img: require("../Image/product/Mulberry.jpg"),
        heading : "Copper Mulberry  ",
        para: "Our Copper Mulberry products stand out for their durability and aesthetic appeal. Known for their strength and versatility, these products are perfect for specialized applications that require both functionality and style. Whether used in decorative elements or functional components, our Copper Mulberry offerings are crafted to the highest standards, ensuring longevity and superior performance. Experience the unique benefits of Copper Mulberry with our premium selection, designed to meet your specific needs.",
        title:"Raika Enterprises",
        keyword_meta:" " ,
        metadata:" " ,
    } ,
    {
        key : 6,
        id:5,
        img: require("../Image/product/Stationery Ready Goods.jpg"),
        heading : "Stationery Ready Goods ",
        para: "Enhance your workspace with our range of Stationery Ready Goods. From stylish notebooks and journals for jotting down your thoughts to high-quality writing instruments that ensure a smooth writing experience, we have everything you need. Our collection also includes essential office supplies like staplers and paper clips, as well as desk accessories designed to keep your workspace organized and inspiring. Combining functionality with style, our stationery products are perfect for boosting productivity and adding a touch of elegance to your office or study.",
        title:"Raika Enterprises",
        keyword_meta:" " ,
        metadata:" " ,
    } 
]
export default ProductData