// src/components/Card.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Card = ({ id, title, description }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the URL with the card's ID
    navigate(`/details/${id}`);
  };

  return (
    <div className="card" onClick={handleClick} style={{ cursor: 'pointer', border: '1px solid #ddd', padding: '10px', margin: '10px', borderRadius: '5px' }}>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );
};

export default Card;
