import React from 'react'
import { useNavigate } from 'react-router-dom';
export default function ProductComponent({ id, heading, img, para ,title , keyword_meta, metadata }) {
    const navigate = useNavigate();

    const handleClick = () => {
        // Navigate to the URL with the card's ID
        navigate(`/details/${id}`, { state: { heading, para, img ,title , keyword_meta, metadata } });
    };

    return (
        <>

            <div className=' p-1 pb-3  main card m-1 h-100'  >
                <div className="p-1 h-100  ">
                    <img src={img} height={"auto"} className='border border-3 border-dark' width={"100%"} alt="image" />
                    <div className="mt-3  ">
                        <p className='text-capitalize fw-bold'>{heading}</p>
                        <a href="/" className='btn btn-dark btn-sm' data-bs-toggle="modal" data-bs-target="#exampleModal">Enquiry Now</a>
                        <button className='btn btn-primary btn-sm  ' onClick={handleClick}>Details</button>
                    </div>
                </div>
            </div>

        </>
    )
}
