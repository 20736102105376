import React from 'react'
import { Helmet } from 'react-helmet';
import WhyChooseUs from "../Components/WhyChooseUs"
import Footer from '../Components/Footer';
export default function Services() {
  return (
    <>
      <Helmet>
        <title>Services</title>
        <meta name="description" content="Services" />
        <meta name="keywords" content="Services" />
        {/* Add other meta tags here */}
      </Helmet>
      <div className="jumbotron jumbotron-fluid    about_bg ">
        <div className="container text-center py-5">
          <h1 className="text-white display-4">Our Services</h1>
        </div>
      </div>

      <WhyChooseUs />
      <Footer />
    </>
  )
}
