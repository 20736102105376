import React from 'react'
import { useNavigate } from 'react-router-dom';
export default function NavLink({ id, heading, img, para,title, keyword_meta, metadata }) {
    const navigate = useNavigate();

    const handleClick = () => {
        // Navigate to the URL with the card's ID
        navigate(`/details/${id}`, { state: { heading, para, img ,title , keyword_meta, metadata} });
    };

    return (
        <>
            <button className='dropdown-item  ' onClick={handleClick}>{heading}</button>
        </>
    )
}
