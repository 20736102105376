import React, { useState } from 'react';

export default function EnquiryModal() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior

        // Construct the WhatsApp URL
        const url = `https://wa.me/919742488718?text=Name:%20${encodeURIComponent(name)}%20Number:%20${encodeURIComponent(number)}%20Email:%20${encodeURIComponent(email)}`;

        // Open the URL in a new tab
        window.open(url, "_blank").focus();
    };
    return (
        <div>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Raika Enterprises
                            </h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12 col-md-6 my-2">
                                    <input type="text" class="form-control name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter Your Name" />
                                </div>
                                <div class="col-12 col-md-6 my-2">
                                    <input type="number" class="form-control number" value={number} onChange={(e) =>  setNumber(e.target.value)} placeholder="Enter Your Number" />
                                </div>
                                <div class="col-12  my-2">
                                    <input type="email" class="form-control email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Your Email" />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" onClick={handleSubmit} class="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
