// src/components/CardList.js
import React from 'react';
import Card from './Card';

const CardList = () => {
  // Example data
  const cards = [
    { id: 1, title: 'Card 1', description: 'Description for card 1' },
    { id: 2, title: 'Card 2', description: 'Description for card 2' },
    { id: 3, title: 'Card 3', description: 'Description for card 3' },
    // Add more cards as needed
  ];

  return (
    <div>
      {cards.map(card => (
        <Card key={card.id} id={card.id} title={card.title} description={card.description} />
      ))}
    </div>
  );
};

export default CardList;
